import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const getData = graphql`
  {
    cat: allContentfulCategorie(filter: { node_locale: { eq: "nl" } }) {
      nodes {
        title
        slug
        node_locale
      }
    }
  }
`
const CatNl = () => {
  const data = useStaticQuery(getData)
  const home = "/"
  return (
    <ul>
      <li>
        <Link to="/nl/handelaren">-Alle-</Link>
      </li>
      {data.cat.nodes.map((item, index) => {
        return (
          <li key={index}>
            <Link
              to={`/${item.node_locale}/handelaren/${item.slug}`}
              activeClassName="is-active"
              partiallyActive={item.path === home ? false : true}
            >
              {item.title}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default CatNl
