import React from "react"
import { graphql, Link } from "gatsby"
import Masonry from "react-masonry-css"
import Img from "gatsby-image"

//components
import Layout from "../components/Layout"
import Section from "../components/Section"

import CatNl from "../components/Menu/CatNl"

//css
import "../css/masonry.css"

const handelaren = ({
  data: {
    com: { nodes },
  },
}) => {
  const langSelect = [
    { locale: "fr", path: "/commercants" },
    { locale: "nl", path: "/handelaren" },
  ]
  const langCurrent = "nl"

  const myColumn = {
    default: 4,
    1200: 3,
    980: 2,
    740: 2,
    500: 1,
  }

  return (
    <Layout langSelect={langSelect} langCurrent={langCurrent}>
      <Section className="main">
        <div className="sidebar">
          <h2>Type</h2>
          <CatNl />
        </div>
        <div className="content">
          <Masonry
            breakpointCols={myColumn}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {nodes.map((item, index) => {
              return (
                <div key={index}>
                  <Link to={`/${langCurrent}/handelaren/${item.slug}`}>
                    <Img fluid={item.logo.fluid} alt={item.title} />
                    <h2>{item.title}</h2>

                    <p>{item.subtitle}</p>
                  </Link>
                </div>
              )
            })}
          </Masonry>
        </div>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query GetAllHandelaren {
    com: allContentfulCommercant(
      filter: { node_locale: { eq: "nl" } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        title
        node_locale
        slug
        subtitle
        logo {
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
`

export default handelaren
